import GetImage from 'components/functional/getImage'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/media'

const SingleTicketStyles = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  .content {
    background: var(--darkGrey);
    padding: 1rem;
    text-align: center;
    .matchday {
      text-transform: uppercase;
      font-size: 1.25rem;
      margin-top: 1rem;
    }
    .matches {
      div {
        margin: 1rem 0;
        display: flex;
        flex-direction: column;
        gap: 2px;
      }
    }
    .buttons {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      a {
        width: 80%;
        margin: 0 auto;
        display: block;
        button {
          span {
            font-size: 0.85rem;
          }
        }
        .alt {
          background-color: var(--pink);
          span {
            color: var(--white);
          }
          &:hover,
          &:active {
            background: var(--blue);
          }
        }
      }
      &:nth-child(1) {
        background-color: var(--pink);
        a span {
          color: var(--white);
        }
      }
    }
  }
  .venue {
    margin-top: 5px;
  }
  .smaller {
    margin-top: 1rem;
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.85);
  }

  .img {
    width: 100%;
    height: 100%;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
  }
  .disclaimer {
    color: var(--darkGrey);
    text-align: center;
    grid-column: 1/-1;
    margin-top: 0.5rem;
    font-size: 0.85rem;
  }
  /* @media ${media.md} {
    display: grid;
    grid-template-columns: 55% 1fr;
    .img {
      width: 100%;
      height: 100%;
      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
    }
  } */
  @media ${media.xl} {
    max-width: 1366px;
    display: grid;
    grid-template-columns: 70% 1fr;
  }
`
const SingleTicket = ({ data, index }) => {
  const {
    date,
    images,
    matches,
    stadium,
    findoutmore,
    purchase,
    secondPurchase,
    text,
    disclaimer,
    hospitality,
  } = data
  console.log(purchase)
  return (
    <SingleTicketStyles>
      <div className="img">
        <GetImage data={images[0].desktop} />
      </div>
      <div className="content">
        <p className="matchday">
          <strong>{`Matchday ${index + 1}`}</strong>
        </p>
        <div className="matches">
          {matches.map((match, i) => (
            <div key={i}>
              <p>
                <strong>{match.match}</strong>
              </p>
              <p>{`Kick off: ${match.kickoff}*`}</p>
            </div>
          ))}
        </div>
        <p>
          <strong>{date}</strong>
        </p>
        <p className="venue">
          <strong>{stadium}</strong>
        </p>
        <div className="buttons">
          {text ? (
            <a href={purchase} target="_blank" rel="noopener noreferrer">
              <button type="button" className="btn btn-main">
                <span>{text}</span>
              </button>
            </a>
          ) : (
            <a href={purchase} target="_blank" rel="noopener noreferrer">
              <button type="button" className="btn btn-main">
                <span>{`${
                  stadium === 'Venue TBC' ? 'COMING SOON' : 'BUY TICKETS'
                }`}</span>
              </button>
            </a>
          )}
          {hospitality && (
            <a href={hospitality} target="_blank" rel="noopener noreferrer">
              <button type="button" className="btn btn-main">
                <span>BUY HOSPITALITY</span>
              </button>
            </a>
          )}
          {secondPurchase && (
            <a href={secondPurchase} target="_blank" rel="noopener noreferrer">
              <button type="button" className="btn btn-main">
                <span>LARGE GROUP TICKETS (9+)</span>
              </button>
            </a>
          )}
          <Link to={findoutmore}>
            <button type="button" className="btn btn-main alt">
              <span>Find out more</span>
            </button>
          </Link>
        </div>
        <p className="smaller">*Kick off times may be subject to change</p>
      </div>
      <p className="disclaimer">{disclaimer}</p>
    </SingleTicketStyles>
  )
}

export default SingleTicket
