import GetImage from 'components/functional/getImage'
import { StaticImage } from 'gatsby-plugin-image'
import YellowX from 'svgs/yellowx.svg'
import Stripes from 'svgs/stripes.svg'
import Crosses from 'svgs/crosses.svg'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/media'
import { MaxWidth } from 'components/global'
import SingleTicket from './single-ticket'

const FixBody = styled.section`
  width: 100%;
  background-color: var(--offWhite);
  position: relative;
  overflow-x: hidden;
  padding-bottom: 14rem;
  #yellowX {
    position: absolute;
    top: 1rem;
    right: -5rem;
    width: 12rem;
    z-index: 0;
    @media ${media.ls} {
      right: -2rem;
    }
    @media ${media.sm} {
      width: 13rem;
      right: 0rem;
      top: 0.5rem;
    }
    @media ${media.lg} {
      right: 2rem;
      top: 2rem;
      width: 14rem;
    }
    @media ${media.xl} {
      right: 6rem;
    }
  }
  #stripes {
    position: absolute;
    bottom: 0rem;
    left: -25rem;
    width: 35rem;
    @media ${media.ls} {
      width: 35rem;
      left: -20rem;
    }
    @media ${media.sm} {
      width: 35rem;
      left: -20rem;
    }
    @media ${media.lg} {
      width: 45rem;
      left: -31rem;
    }
    @media ${media.xl} {
      width: 50rem;
      left: -31rem;
    }
  }
  .tickets {
    position: relative;
    z-index: 10;
    padding: 5rem 0;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .text {
    text-align: center;
    color: var(--darkGrey);
    position: relative;
    z-index: 10;
    margin: 4rem auto 0 auto;
    width: 90%;
  }
`

const Body = ({ data }) => (
  <FixBody>
    <div className="text">
      <p>
        <strong>
          Two great matches, one ticket! Each matchday will be a Double-Header
          with tickets enabling access to both matches.
        </strong>
      </p>
      <p>
        Stadium re-entry restrictions may apply. See the find out more pages for
        further details.
      </p>
    </div>
    <YellowX id="yellowX" />
    <div className="tickets">
      {data.map((ticket, i) => (
        <SingleTicket data={ticket} index={i} key={i} />
      ))}
    </div>
    <Stripes id="stripes" />
  </FixBody>
)

export default Body
